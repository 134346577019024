import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <div className="bg-primary footer text-white text-center font-weight-light">
        © 2020 Rudolfs Software Development. All Rights Reserved.
      </div>
    )
  }
}

export default Footer
