import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image";

export default function Header() {
    return (
        <StaticQuery
            query={graphql`
        query {
    headerImageFluid: file(relativePath: { eq: "images/header_logo_desktop.png" }) {
      childImageSharp {
        fluid(maxWidth: 2560, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    mobileImage: file(relativePath: { eq: "images/header_logo_mobile.png" }) {
                childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid
            }
            }
            }
    desktopMediumImage: file(
                relativePath: { eq: "images/header_logo_desktop_medium.png" }
                ) {
                childImageSharp {
                fluid(maxWidth: 1550, quality: 100) {
                ...GatsbyImageSharpFluid
            }
            }
            }
    desktopImage: file(
                relativePath: { eq: "images/header_logo_desktop.png" }
                ) {
                childImageSharp {
                fluid(maxWidth: 2000, quality: 100) {
                ...GatsbyImageSharpFluid
            }
            }
            }
  }
      `}
            render={data => (

                <header className="header">
                    <Image className="mx-auto d-block" fluid={[data.mobileImage.childImageSharp.fluid,
                        {
                            ...data.desktopImage.childImageSharp.fluid,
                            media: `(min-width: 1280px)`,
                        },
                        {
                            ...data.desktopMediumImage.childImageSharp.fluid,
                            media: `(min-width: 768px)`,
                        },
                    ]} />
                </header>
            )}
        />
    )
}
