import React, { Component } from 'react'
import {Link} from 'gatsby'
import {
  Button,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Container,
  Row,
  Col,
} from 'reactstrap'
import './layout.scss'
import Header from './header';
import Footer from './footer';
import { Helmet } from 'react-helmet';
require("prismjs/plugins/line-numbers/prism-line-numbers.css")

class Layout extends Component {

  constructor(props) {
    super(props)

    this.children = props.children
    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    const {
      children,
      // content,
      // contentComponent,
      // description,
      // tags,
      // title,
      // helmet,
    } = this.props

    // const navLinkStyle = {
    //   padding: 0
    // }

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Rudolfs Software Development</title>
          <meta name="description" content="Software Development"/>
        </Helmet>
        <Navbar color="primary" dark expand="lg" fixed="top" id="navbar1">
          <div className="container">
            <NavbarBrand href="/">
              <span className="navbar-brand">Freelance Software Development</span>
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <Link to="/">
                    <Button color="primary" block>
                      Home
                    </Button>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to="/about">
                    <Button color="primary" block>
                      About
                    </Button>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to="/blog">
                    <Button color="primary" block>
                      Blog
                    </Button>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to="/contact">
                    <Button color="primary" block>
                      Contact
                    </Button>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to="/events">
                    <Button color="primary" block>
                      Events
                    </Button>
                  </Link>
                </NavItem>
                {/* <NavItem>
                  <NavLink style={navLinkStyle}
                    href="https://www.linkedin.com/in/christiaan-rudolfs-334a6314/"
                    target="_blank" rel="noopener noreferrer">
                    <Button color="primary" block>
                      LinkedIn
                    </Button>
                  </NavLink>
                </NavItem> */}
              </Nav>
            </Collapse>
          </div>
        </Navbar>
        <Header/>
        <Container>
          <Row>
            <Col sm="12">{children}</Col>
          </Row>
        </Container>
        <Footer/>
      </div>
    )
  }
}

export default Layout
